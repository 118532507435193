import React from "react"
import Layout from "../components/note-layout"
import { Helmet } from "react-helmet";
import "../components/portfolio.css"


export default function Home() {
  return <Layout>
    <Helmet>
    <title>
    [Working Title]
    </title>
    </Helmet>
  </Layout>
}
